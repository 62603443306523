
import Page from '@/views/Page.vue';
import { PageState } from '@/models/PageState';
import { Component, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, StringsMixin, PaginatedTableMixin, CoachRoutingMixin, BAIconsMixin } from '@/mixins';
import { DataTableHeader } from 'vuetify';
import { adminStore } from '@/store/index';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import RecruitingProfileInfo from '@/components/profile/recruiting/RecruitingProfileInfo.vue';
import TeamsSummary from '@/components/orgAdmin/TeamsSummary.vue';
import { Gender } from '@/../types/enums';
import { title } from '@/pipes/title.pipe';
import { AgeGroup, AgeGroupValues, DominantSide, DominantSideValues } from '@best-athletes/ba-types';
import { BaseSoccerPosition, BaseSoccerPositionValues } from '@/../types/enums/sports';
import ChipGroup from '@/components/forms/ChipGroup.vue';

@Component({
	components: {
		Page,
		RecruitingProfileInfo,
		TeamsSummary,
		ChipGroup,
	}
})
export default class CoachCentralScoutingDashboard extends Mixins(VuetifyMixin, StringsMixin, PaginatedTableMixin, CoachRoutingMixin, BAIconsMixin) {
	search: string = '';
	titleCase = title;

	mounted() {
		this.tableOptions.sortBy = ['lastName'];
	}

	panel = [0];
	resetFilters(): void{
		this.search = "";
		this.ageFilter = [];
		this.positionFilter = [];
		this.dominantSideFilter = [];
		this.genderFilter = [];
		this.updateTable();
	}

	genderFilter: Gender[] = [];
	get TeamGenderValues(): Gender[]{
		return [
			Gender.Male,
			Gender.Female,
		];
	}

	ageFilter: AgeGroup[] = [];
	get AgeGroupValues(): AgeGroup[]{
		return AgeGroupValues;
	}
	get AgeFilter(): number[]{
		return this.ageFilter.map(ageGroup => {
			return parseInt(ageGroup.replace(/[A-Za-z]/, ''));
		});
	}

	positionFilter: BaseSoccerPosition[] = [];
	get PositionValues(): BaseSoccerPosition[]{
		return BaseSoccerPositionValues;
	}

	dominantSideFilter: DominantSide[] = [];
	get DominantSideValues(): DominantSide[]{
		return DominantSideValues;
	}


	pageState: PageState = new PageState('Initial');
	async loadTable(): Promise<void>{
		this.pageState = new PageState('Loading');
		try{
			var query = this.TableQuery<AthleteProfileModel>(this.search, ['firstName', 'lastName']);
			if( !!this.genderFilter && this.genderFilter.length > 0 ) { query.$match = {...query.$match, ...{ 'gender': { $any: this.genderFilter.map(g => [g.toLowerCase(), title(g)]).reduce((a,b) => [...a,...b]) } } } };
			// if( !!this.ageFilter && this.ageFilter.length > 0 ) { query.$match = {...query.$match, ...{ 'Age': { $any: this.ageFilter } } } };
			if( !!this.positionFilter && this.positionFilter.length > 0 ) { query.$match = {...query.$match, ...{ 'primaryPosition': { $any: this.positionFilter } } } };
			if( !!this.dominantSideFilter && this.dominantSideFilter.length > 0 ) { query.$match = {...query.$match, ...{ 'dominantSide': { $any: this.dominantSideFilter } } } };
			await adminStore.queryAthletes({ query, options: this.TableQueryOptions });
			this.dataItemsCount = adminStore.athletesCount;
		}catch(e){
			console.error(e);
		}finally{
			this.pageState = new PageState('Ready');
		}
	}
	get AthletesLoading(): boolean {
		return adminStore.IsAthletesLoading;
	}

	get TableHeaders(): Array<DataTableHeader<any>> {
		return [
			{ text: 'Name', value: 'lastName', sortable: false },
			{ text: 'Team', value: 'team', sortable: false },
			{ text: 'Country', value: 'country', sortable: false },
			{ text: 'City', value: 'city', sortable: false },
			{ text: 'Grad', value: 'gradYear', sortable: false },
			{ text: 'Age', value: 'Age', sortable: false },
			{ text: 'Height', value: 'height', sortable: false },
			{ text: 'Weight', value: 'weight', sortable: false },
			{ text: 'Pos', value: 'primaryPosition', sortable: false },
			{ text: 'Side', value: 'dominantSide', sortable: false },
			{ text: 'Gender', value: 'gender', sortable: false },
			{ text: 'email', value: 'email', sortable: false },
			{ text: '', value: 'data-table-expand' }
		]
	}

	get PageLoading(): boolean {
        return this.tableUpdatePending || adminStore.IsAthletesLoading;
    }
	expandedAthletes = [];
	get Athletes(): AthleteProfileModel[] {
		return adminStore.athletes;
	}
	get NumAthletes(): number {
		return adminStore.athletesCount;
	}
}

